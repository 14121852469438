import React from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CertificateLink from "../../CertificateLink";
import getSymbolFromCurrency from "currency-symbol-map";

function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const MockDocTableColumn = (row,language,userinfo)  => {
        
const columns =[
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS_SR,
        accessor: "",
        className:"text-center align-middle mr-3",
        Cell: (row) => {
            return <div>{(parseInt(row.row.id) + 1)}</div>;
        },
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: language.DETAIL_DIAMONDDETAILS,
        accessor: (d) => (
            <>
            {d.diamondType === "PN" || d.diamondType === "PL" ? 
                <div className="row">
                    <div className="col-2 text-center p-0">
                        <img
                            className="img-fluid mr-2"
                            alt={d.cShape}
                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${d.cShape.toLowerCase()}.svg`)}
                            width="50px"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/svg_shape/parcel/other.svg`
                                    )
                                }}
                        />
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "PN" ? "natTab" : "labTab"}`}>
                            <span className={`${d.diamondType}_diamond mr-3 small`}>
                                {d.diamondType}
                            </span>
                            <span className={`cursor-pointer small ml-2`}>
                                {d.diamondType}-{d.stockNO}
                            </span>
                            <span className={`text-capitalize small ml-2`}>
                                By: {d.type}
                            </span>
                            <span className={`text-capitalize small ml-2`}>
                                {d.length_mm?d.length_mm.toFixed(2):"" }-{d.width_mm?d.width_mm.toFixed(2):"" } MM
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {d.cWeight?d.cWeight.toFixed(2):""}Ct.{" "}
                            {d.pieces}Pcs.{" "}
                            {d.cColor}{" "}
                            {d.cClarity}{" "}
                        </span>
                    </div>
                </div>
             :
                <div className="row">
                    <div className="col-2 text-center p-0">
                        {d.awsImage &&  d.awsImage !=="null" ? (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={d.awsImage}
                                width="50px"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                        )
                                    }}
                            />
                        ) : (
                            <img
                                className="img-fluid mr-2"
                                alt={d.cShape}
                                src={toAbsoluteUrl(
                                    `/media/shape/${d.cShape ? d.cShape.toLowerCase() : ""}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                width="50px"
                                style={{ backgroundColor: "#f4f4f4" }}
                            />
                        )}
                    </div>
                    <div className="col-10 pl-2 p-0 text-left">
                        <div className={`mb-1 ${d.diamondType === "N" ? "natTab" : "labTab"}`}>
                            <span className={`${ d.diamondType === "N" ? d.cColor === "fancy" ? "F" : "W" : d.cColor === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                {d.diamondType === "N" || d.diamondType === "W" ? d.cColor === "fancy"  ? "NF" : "N" : d.cColor === "fancy" ? "LF" : "L"}
                            </span>
                            
                            <CertificateLink lab={d.lab} certificate={d.certiNO} Certificate_link={d.certificate_link} className={`mx-2 small`} />
                            <span  className={`cursor-pointer small ml-2`}>
                                {d.diamondType === "N" ? "N" : "L"}-{d.stockNO}
                            </span>
                        </div>

                        <span className="text-dark font-weight-bold d-block">
                            {d.cShape}{" "}
                            {d.cWeight}{" "}
                            {d.cColor === "fancy" ? (
                                <>
                                    {d.f_color}{" "}
                                </>
                            ) : (
                                <>{d.cColor}{" "}</>
                            )}
                            {d.cClarity}{" "}{d.cCut}{" "}{d.cPolish}{" "}{d.cSymmetry}{" "}
                            {d.cFluorescence}
                        </span>
                    </div>
                </div> 
                }
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
   
    {
        Header:language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION,
        className:"text-center align-middle mr-3",
        accessor: "country",
    },
    {
        Header:language.PROFORMA_DETAIL_DIAMONDS_HSCODE,
        className:"text-center align-middle mr-3",
        accessor: (d) => (
            <> 
                {d.diamondType === "N" || d.diamondType === "W" ? "71023910" : d.diamondType === "L"?"71049120":d.diamondType === "PL"?"71049190":d.cg_carat > 0.99?"71023920" : "71023910"}
            </>
        ),
    },
   
    {
        Header:language.SEARCHLIST_DISC,
        className:"text-center align-middle mr-3",
        // accessor: d => Number(d.discount),
        sortMethod: (a, b) => Number(a)-Number(b),
        // Cell: (row) => {
        //     return <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span>;
        //   }
        accessor:(d) => {
            return <>{d.diamondType === "N" || d.diamondType === "W" || d.diamondType === "L"?<span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{d.discount}%</span>:"-"}</>;
        }
    },
    // {
    //     Header:'$/CT',
    //     className:"text-center align-middle mr-3",
    //     accessor: d => Number(d["ourRate"]),
    //     sortMethod: (a, b) => Number(a)-Number(b),
    //     Cell: (row) => {
    //         return <span className="font-weight-bold">
    //                 ${numberWithCommas(parseFloat(row.value))}
    //             </span>
    //       }
    // },
    // {
    //     Header:language.INVOICE_TOTAL_USD,
    //     className:"text-center align-middle mr-3",
    //     accessor: d => Number(d["ourPrice"]),
    //     sortMethod: (a, b) => Number(a)-Number(b),
    //     Cell: (row) => {
    //         return <span className="font-weight-bold">
    //                 ${numberWithCommas(parseFloat(row.value))}
    //             </span>
    //       }
    // },
    {
        Header: (
            <span>
                {language.INVOICE_TOTAL_USD} <br />
                <span className="text-nowrap">$/CT</span>
            </span>
        ),
        id:"ourprice",
        sortMethod: (a, b) => Number(a)-Number(b),
        accessor: (d) => (
            <div className="w-75 text-right">
                <div className="font-weight-bold">
                    ${numberWithCommas(parseFloat(d["ourPrice"]))}
                </div>
                <div className="text-muted small">
                    $/CT {numberWithCommas(parseFloat(d["ourRate"]))}
                </div>
            </div>
        ),
        className: "text-center text-nowrap align-middle",
    }
];

if (userinfo?.cb_global_curr && userinfo?.cb_global_curr !== "USD") {
    columns.splice(7, 0, {
        Header: <span>Our Price <br /><span className="text-nowrap">(Other Currency)</span></span>,
        id:"ourpriceothercurr",
        accessor: (d) => (
            d.cur_symbol_exchange && d.cur_symbol_exchange !== "USD" ?
                <>
                {console.log(d.cur_symbol_exchange)}
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip"> Ex. Rate : {d.cur_symbol_exchange} { getSymbolFromCurrency(d.cur_symbol_exchange)}{d.cur_rate_exchange}</Tooltip>}
                    >
                        <div className="w-75 text-right">
                            <div className="font-weight-bold">
                                { getSymbolFromCurrency(d.cur_symbol_exchange)}{numberWithCommas(parseFloat(d["ourPriceExchange"]))}
                            </div>{" "}
                            <div className="text-muted small">
                                {d.cur_symbol_exchange}/CT {numberWithCommas(parseFloat(d["ourRateExchange"]))}
                            </div>
                        </div>
                    </OverlayTrigger>
                    
                </>
            : "-"
        ),
        className: "text-right text-nowrap align-middle",
    });
}

return columns;
}
