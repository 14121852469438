/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useState} from "react";
import { toAbsoluteUrl } from "../../_helpers";
import { useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import CertificateLink from "../CertificateLink";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};


export const SuccessDiamondDetails = (props) => {
    const [data] =  useState(props.data)
    const history = useHistory();

    return (
        <>
            <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll w-100"
                style={{
                    maxHeight: "450px",
                    position: "relative",
                }}
            >
                { data.map((data)=>(
                    <div key={data.id} className="d-flex mb-4 pb-4 px-4 border-bottom justify-content-between">
                        {data.diamond_type === "PN" || data.diamond_type === "PL" ?<div className="d-flex">
                            <div className="text-center p-0">
                                <img
                                    className="img-fluid mr-2"
                                    alt={data.C_Shape}
                                    src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${data.C_Shape.toLowerCase()}.svg`)}
                                    width="50px"
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/svg_shape/parcel/other.svg`
                                            )
                                        }}
                                />
                            </div>
                            <div className="pl-2 p-0 text-left">
                                <div className={`mb-1 ${data.diamond_type === "PN" ? "natTab" : "labTab"}`}>
                                    <span className={`${data.diamond_type}_diamond mr-3 small`}>
                                        {data.diamond_type}
                                    </span>
                                    <span className={` cursor-pointer small ml-2`}>
                                        {data.diamond_type}-{data.ParcelID}
                                    </span>
                                    <span className={` text-capitalize small ml-2`}>
                                        By: {data.type}
                                    </span>
                                    <span className={` text-capitalize small ml-2`}>
                                        {data.length_mm?data.length_mm.toFixed(2):"" }-{data.width_mm?data.width_mm.toFixed(2):"" } MM
                                    </span>
                                </div>
                                <span className="text-dark font-weight-bold d-block">
                                    {data.C_Shape}{" "}
                                    {data.cg_carat?parseFloat(data.cg_carat).toFixed(2):""}Ct.{" "}
                                    {data.pieces}Pcs.{" "}
                                    {data.C_Color}{" "}
                                    {data.C_Clarity}{" "}
                                </span>
                                <div className="mt-3">
                                    <span className="font-weight-bold mr-3 ">{props.language.SEARCHLIST_LOCATION}:</span> {data.country}
                                    
                                </div>
                            </div>
                            
                        </div>:<div className="d-flex">
                            <div className="text-center p-0">
                                {data.aws_image &&  data.aws_image !=="null" ? (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={data.C_Shape}
                                        src={data.aws_image}
                                        width="100px"
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/${data.C_Shape ? data.C_Shape.toLowerCase() : ""}-no.png`
                                                )
                                            }}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={data.C_Shape}
                                        src={toAbsoluteUrl(
                                            `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                        width="100px"
                                        style={{ backgroundColor: "#f4f4f4" }}
                                    />
                                )}
                            </div>
                            <div className="pl-2 p-0 text-left">
                                <div className={`mb-1 ${data.diamond_type !== "L" ? "natTab" : "labTab"}`}>
                                    <span className={`${ data.diamond_type !== "L" ? data.C_Color === "fancy" ? "F" : "W" : data.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                        {data.diamond_type !== "L" ? data.C_Color === "fancy"  ? "NF" : "N" : data.C_Color === "fancy" ? "LF" : "L"}
                                    </span>
                                    <span onClick={() => history.push({ pathname: `/detail/${data.diamond_type !== "L" ? "N" : "L"}-${data.id}` })} className={` cursor-pointer small ml-2`}>
                                        {data.diamond_type !== "L" ? "N" : "L"}-{data.id}
                                    </span>
                                    
                                    <CertificateLink Certificate_link={data.certificate_link} lab={data.Lab} certificate={data.Certi_NO}  className={`mx-2 text-dark small`} />
                                </div>
                                <span className="text-dark font-weight-bold d-block">
                                    {data.C_Shape}{" "}
                                    {data.cg_carat}{" "}
                                    {data.C_Color === "fancy" ? (
                                        <>
                                            {data.f_color}{" "}
                                        </>
                                    ) : (
                                        <>{data.C_Color}{" "}</>
                                    )}
                                    {data.C_Clarity}{" "}{data.C_Cut}{" "}{data.C_Polish}{" "}{data.C_Symmetry}{" "}
                                    {data.C_Fluorescence}
                                </span>
                                <div className="mt-3">
                                    <span className="font-weight-bold mr-3 ">{props.language.SEARCHLIST_LOCATION}:</span> {data.country}
                                    <img
                                        className="img-fluid flagIco ml-1"
                                        alt={data.country}
                                        src={toAbsoluteUrl(
                                            `/media/map/${data.country && data.country.toLowerCase()}.png`
                                        )}
                                    />
                                </div>
                                {/* <div className="mt-3">
                                    <span className="font-weight-bold mr-3">Delivery Days:</span> {data.shipping} days
                                </div> */}
                            </div>
                            
                        </div>}
                        <div>
                            <div className="text-right">
                                <p className="text-muted mb-2">$/CT</p>
                                <p className="text-dark font-weight-bold">${data["T_C_Rate"]}</p>
                            </div>
                            <div className="text-right">
                                <p className="text-muted mb-1">{props.language.SEARCHGRID_TOTAL}</p>
                                <p className="text-dark font-weight-bold">${data["price"]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </PerfectScrollbar>
            <div className="mt-3"> {data.length > 3 ? <span className=" font-weight-bold">Scroll for more</span>: ""} </div>
        </>
    );
};
