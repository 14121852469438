import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { LiaAwardSolid } from 'react-icons/lia'; // Assuming you're using react-icons for icons

const CertificateLink = ({ lab, certificate, Certificate_link, useIcon = false, className, hideLabName = false, withExternalLinkIcon= false }) => {

  return (
    Certificate_link ? (
      <a
        href={Certificate_link}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {useIcon ? <LiaAwardSolid /> : `${!hideLabName ? `${lab}: ` : ''}${certificate}`}
        {withExternalLinkIcon ? <FaExternalLinkAlt className='ml-1' />:""}
      </a>
    ) : null
  );
};

export default CertificateLink;
