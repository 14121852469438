import React, { useState,useRef, useEffect } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import { FaExternalLinkAlt,FaInfoCircle} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
// import {GiCutDiamond} from "react-icons/gi"
import {Modal,Button} from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import VideoIframe from "../../detailPage/VideoIframe";
import SVG from "react-inlinesvg";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import { HOLDORBUY } from '../../../../env_config';  
// import {ConfirmGoods,HoldPopup} from '../Popup'

import { SwalWarn } from "../../Popup";
import { TracklistStone } from '../../Popup/TracklistPopup';
import { ImageExpandPopupCommon } from '../../widgets/tiles/ImageExpandPopupCommon';
import { VideoExpandPopupCommon } from '../../widgets/tiles/VideoExpandPopupCommon';
import { MainImageExpandedDetailsPopup } from '../../widgets/tiles/MainImageExpandedDetailsPopup';
import { CopyUrlAlert } from '../../Popup/CopyUrlAlert';
import CertificateLink from '../../CertificateLink';
import axios from 'axios';
import { BASE_URL, NODE_API_URL } from '../../../../env_config';
import Cookies from "js-cookie";
export default function ExpandedDetaisChurned({row,conversionrate,symbol,language,BuyDiamond,HoldDiamond,symbolname,userinfo}){
    const [show,setShow] = useState(false)
    const [diamonddetails,setDiamondDetail] = useState([])
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);

    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast] = useState(false)
    const [wishlistmessage] = useState(null)
    // const history = useHistory()
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.lab){
        //     title += row.lab
        // }
        if(row.shape){
            title += " "
            title += row.shape
        }
        if(row.carat){
            title += " "
            title += row.carat?.toFixed(2) 
        }
        // if(row.fancy_intensity ){
        //     title += " "
        //     title += row.fancy_intensity 
        // }
        if(row.color){
            title += " "
            title += row.color
        }
        if(row.clarity){
            title += " "
            title += row.clarity
        }

        if(row.cut){
            title += " "
            title += row.cut
        }
       
        if(row.polish){
            title += " "
            title += row.polish
        }

        if(row.symmetry){
            title += " "
            title += row.symmetry
        }

        if(row.fluorescence){
            title += " "
            title += row.fluorescence
        }
        return title
    }
    const RouteToDetail = (row) => {
        const url = `/detail/${row.diamondType}-${row.stockID}?SearchId=${row.search_result_id}`;
        window.open(url, '_blank');
    };
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    useEffect(() => {
        const finalData=`
             {
                 "certificate":"${row.certificate}",
                 "shape":"${row.shape}",
                 "carat":"${row.carat}",
                 "color":"${row.color}",
                 "clarity":"${row.clarity}",
                 "stockId":"${row.stockID}",
                 "ourPrice":"${row.ourPrice}",
                 "supplierPrice":"${row.supplierPrice}",
                 "supp_id":"${row.sup_id}",
                 "curr":"${symbolname}",
                 "curr_rate":${conversionrate},
                 "search_result_id":"${row.search_result_id}",
                 "userId":"${userinfo.id}",
                 "diamondType":"${row.diamondType}",
                 "loginLogId":${Cookies.get("_LogId")}
             }
        `
         axios({
             method: "POST",
             url: `${NODE_API_URL}/INTL/fetchDiamondExpand`,
             headers: {
                 "Content-Type": "application/json",
                  "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
             },
             data: finalData,
         }).then(function(res) {
             
         }).catch(error => {
             if(error?.response?.data?.message === "UnAuthorized!"){
                 var cookies = document.cookie.split(";");
                 for (var i = 0; i < cookies.length; i++) {
                     if(cookies[i] && !cookies[i].includes("view")){
                     var equals = cookies[i].indexOf("=");
                     var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                     }
                 }
                 window.location.replace(`${BASE_URL}/logout`);
             }
         })
     
 },[])
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }
  
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
   
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    const AddToTracklist = (row) => {
        setShow(true)
        setDiamondDetail([{
            "Certi_NO":row.certificate,
            "diamond_type":row.diamondType,
            "tracklisted_price_usd":row.ourPrice,
            "tracklisted_price":Math.round(row.ourPrice * conversionrate * 100)/100
        }])
    }
    return(
       <>
       <TracklistStone SearchId={row.search_result_id} show={show} setShow={setShow} language={language} diamonddetails={diamonddetails} userinfo={userinfo} symbolname={symbolname} conversionrate={conversionrate}/>
        <div className={`expandedDetails ${row.diamondType === "N" ? "natTab" : "labTab" } ${row.isfancy ? "fancyExpand" : "" }`} >
            <div className="row pt-2">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        
                            <ImageExpandPopupCommon data={row}/>
                            <VideoExpandPopupCommon  data={row} userinfo={userinfo}/>
                        {row.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/heartImage.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-5.png"/> */}
                            </li>
                            
                        ):('')}
                        {row.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/arrowImage.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-6.png"/> */}
                            </li>
                        ):('')}
                        {row.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <SVG  width="40" src={toAbsoluteUrl("/media/General/assetIcon.svg")} />
                                {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-3.png"/> */}
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4 ribbon ribbon-top">
                    {row.diamondType === "N" || row.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                        <MainImageExpandedDetailsPopup data={row}/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 mt-3 mb-2 d-flex align-items-center">
                            <div className="mr-2 border-right font-size-sm">
                            <>
                                       {row.lab}: <CertificateLink Certificate_link={row.certificate_link} lab={row.lab} certificate={row.certificate} hideLabName withExternalLinkIcon className={`text-dark mx-2 text-left`} />
                                        <CopyUrlAlert text={row.certificate}/>
                                    </>
                               
                            </div>
                            <div className='font-size-sm mr-2'><div className="d-inline-block mr-2 cursor-pointer" >{row.diamondType + "-" + row.stockID}</div>
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h4 text-capitalize font-weight-bold">{GenerateTitle(row)}</span>
                        </div>
                        <div className='col-12 advTag mb-3'>
                            {/* shade */}
                            {renderShadeLabel(row.shade)}
                            
                            { row.milky  && row.milky .toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky  && row.milky .toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky  && row.milky .toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky  && row.milky .toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* eyeclean */}
                            { row.eyeClean  && row.eyeClean .toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.eyeClean  && row.eyeClean .toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* growthtype */}
                            { row.diamondType === "L" && (row.growthType && row.growthType === "CVD") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                CVD
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.diamondType === "L" && (row.growthType && row.growthType === "HPHT") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                HPHT
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            
                            { (row.diamondType === "L" ) && (row.treatment && row.treatment.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (row.diamondType === "N" || row.diamondType === "W") && (row.treatment  && row.treatment .toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                       
                        {!row.supplierName ? (
                            ""
                        ):(
                            <div className="col-12">
                                { userinfo?.v_customer?.toString() ==="7" || userinfo?.v_customer?.toString() ==="11" ? (
                                    <span className="font-size-sm text-dark mr-5"><span className="text-muted font-size-sm  text-capitalize">{language.MUI_SUPPLIER} :</span> ***************</span>
                                ) :(
                                    <span className="font-size-sm text-dark mr-5"><span className="text-muted font-size-sm  text-capitalize">{language.MUI_SUPPLIER} :</span> {row.supplierName}</span>
                                )}
                                 <span className="text-dark font-size-sm"> 
                                        <span className='text-muted'>{language.SEARCHLIST_LOCATION}:</span> {row.location ? row.location : "-"}
                                        {row.location ? (
                                            <OverlayTrigger
                                                placement="top"
                                                className="text-capitalize"
                                                overlay={
                                                    <Tooltip id="tooltip">{row.location}</Tooltip>
                                                }
                                            >
                                                <img
                                                    className="flagIco ml-1"
                                                    alt={row.value}
                                                    src={toAbsoluteUrl(
                                                        `/media/map/${row.location.toLowerCase()}.png`
                                                    )}
                                                    onError={e => {
                                                        e.currentTarget.src =toAbsoluteUrl(
                                                            `/media/map/other.png`
                                                            )
                                                        }}
                                                />
                                            </OverlayTrigger>
                                        ) : (
                                            null
                                        )}
                                    </span>
                            </div>
                        )}
                        
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark"><span className="font-size-sm text-muted text-capitalize">{language.MUI_LISTPRICE}: </span>${row.listPrice?row.listPrice:"0000"}</span>
                            <span className="font-size-lg text-dark ml-8"><span className="font-size-sm text-muted text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.shippingDays }{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row"> 
                            {!row.supplierName?(
                                ""
                            ) : (
                                <div className="col-6">
                                    <div className="price_border_right"></div>
                                    <div className="px-0">
                                        <h5>
                                            {language.MUI_SUPPLIERPRICE}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Price Provided by Supplier
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                </OverlayTrigger>
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.supplierDiscount ?row.supplierDiscount .includes('-')?
                                        <div className="d-flex small text-danger align-items-center font-size-lg">{row.supplierDiscount }% Off<span className="ml-8 text-dark">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center font-size-lg">{row.supplierDiscount }% PR<span className="ml-8 text-dark ">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center font-size-lg">0.00% Off<span className="ml-8 text-dark">({symbolname})</span></div>} 
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.supplierPrice )}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.supplierPrice  * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.supplierPrice  * conversionrate)}</>
                                            </span>
                                        }
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.supplierRate )}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.supplierRate  * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.supplierRate  * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            )}
                                <div className="col-6">
                                    <div className="px-0">
                                        <h5 className="position-relative">
                                            {language.MUI_OURPRICE}
                                            {row.certificate  === 0 ? (
                                                ""
                                            ):(
                                                <>
                                                    <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                    <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                        {props => (
                                                            <Tooltip id="overlay-example" {...props}>
                                                            Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                            </Tooltip>
                                                        )}
                                                    </Overlay>
                                                </>
                                            )}
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.ourDiscount ?row.ourDiscount .includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.ourDiscount }% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.ourDiscount }% PR<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>}
                                        
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.ourPrice)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.ourPrice * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.ourPrice * conversionrate)}</>
                                            </span>
                                        }  
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.ourRate )}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.ourRate  * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.ourRate  * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex align-items-center">
                            <button type="button" className="btn btn-primary px-12 mr-3" onClick={() =>  BuyDiamond([{original:row}]) }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.isfancy) ? (
                                <>
                                    <div className="font-size-h5 text-dark  mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 ">  {row.fancy_intensity  ? row.fancy_intensity  : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 ">  {row.fancy_overtone  ? row.fancy_overtone  : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 ">  {row.fancy_color  ? row.fancy_color  : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 "> {row.length?.toFixed(2) } * {row.width?.toFixed(2)} * {row.height?.toFixed(2)} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 "> {row.tablePercent  ? row.tablePercent?.toFixed(2)  : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 "> {row.depthPercent  ? row.depthPercent?.toFixed(2)  : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 ">
                                    {row.crownHeight ? row.crownHeight?.toFixed(2) : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 ">
                                    {row.crownAngle ? row.crownAngle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 ">
                                    {row.pavilionHeight ? row.pavilionHeight?.toFixed(2) : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 ">
                                    {row.pavilionAngle ? row.pavilionAngle?.toFixed(2) : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 ">
                                {row.ratio?row.ratio.toFixed(2):"-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 ">
                                {row.girdle ? row.girdle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 ">
                                {row.girdleCondition  ? row.girdleCondition : "-"}
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bold mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 ">
                                        {row.brand ? row.brand : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 ">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 ">
                                        {row.milky   ? row.milky  : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 ">
                                        {row.eyeClean  ? row.eyeClean  : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 "> {row.culet  ? row.culet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 ">{row.keyToSymbol ? row.keyToSymbol : "-" }</div>
                                </div>
                                    {row.diamondType === "L" ? 
                                        <>
                                            { (row.growthType) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 ">  {row.growthType ? row.growthType : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.treatment ) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.treatment  ? row.treatment  : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.origin) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 ">  {row.origin ? row.origin : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.brand) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 ">  {row.brand ? row.brand : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.treatment ) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.treatment  ? row.treatment  : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.heart} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.arrow} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.asset} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row}
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}