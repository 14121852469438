import React, { useState, useEffect, useMemo } from "react";
import { SearchGridDesignNew } from "../widgets/tiles/SearchGridDesignNew";
import { useLocation, useHistory } from "react-router-dom";
import { createPortal } from 'react-dom';
import { BsLightningFill } from "react-icons/bs";
import { CiBoxList } from "react-icons/ci";
import { IoGitCompareOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { FaEllipsisH } from "react-icons/fa";
import { Tooltip,OverlayTrigger,Dropdown } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import axios from "axios"
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError,CompareDiamond } from "../Popup";
import SearchGridSkeleton from "./SearchGridSkeleton"
import { Skeleton } from "@material-ui/lab";
import ModifiedSearchOffCanvasGrid from "./ModifiedSearchOffCanvasGrid";
import { SwipeableDrawer } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_helpers";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie";
import { TracklistStone } from "../Popup/TracklistPopup";
import Sorting from "./Sorting";
import { SetCookies } from "../../_helpers/Cookies";

export default function SearchGrid({conversionrate,symbol,language,isprofilecomplete,userinfo,symbolname}) {
    const [sortobj,setSortObj] = useState({id:"",sortorder:""})
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const id = React.useMemo(() => localStorage.getItem("C_id"), []);
    const user = JSON.parse(userData)
    const [infiniteloader,setinfiniteLoader] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const [show,setShow] = useState(false)
    const [diamonddetails,setDiamondDetail] = useState([])
    const [StockAPI, setStockAPI] = useState(location.state.searchresult);
    const [dimondType] =useState(StockAPI.length > 0 && StockAPI[0].diamondType? StockAPI[0].diamondType:"N")
    const [loader,setLoader] = useState(false)
    const [updatecount,setUpdateCount] = useState(false)
    const [countloader,setCountLoader] = useState(true)
    const [modifymsg,setModifyMessage] = useState("")
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [page, setPage] = useState(1);
    const [stoneCount,setStoneCount] = useState(0)
    const [isDropdown, setIsDropdown] = useState(false);
    const scrollRef = React.useRef(null);
    const ReloadPage = () => {
        setLoader(true)
        const fancy = location.state.oldvalues.diamond_type === "N" ? Cookies.get("fancy") ? 1 : 0 : Cookies.get("fancybasic") ? 1 : 0
        window.Nat = location.state.oldvalues;
        let len = Object.keys(location.state.oldvalues).length;
        const temp = location.state.oldvalues;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${id === "" ? "-1" : id}, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":1, "conversionrate":"${conversionrate}","curr":"${symbolname}","fancy":"${fancy}","loginLogId":${Cookies.get("_LogId")}}`;
        if (location.state.oldvalues.diamond_type === "N") {
            localStorage.setItem("modifysearch", JSON.stringify({ ...location.state.oldvalues }))
        }
        else {
            localStorage.setItem("modifysearchlab", JSON.stringify({ ...location.state.oldvalues }))
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/searchDiamond`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
            },
            data: finalData,
        })
            .then(function (res) {
                setStockAPI(res.data.data)
                setPage(1)
                history.push({
                    pathname: `/search-grid/${StockAPI[0].diamondType}`,
                    state: {
                        searchresult: res.data.data,
                        oldvalues: location.state.oldvalues,
                        page: 1
                    },
                    // state: decompress(res.data.ProductDetails),
                });
                setTimeout(() => {
                    setLoader(false)
                   },1000) 
            })
            .
            catch(error => {
                if (error?.response?.data?.message === "UnAuthorized!") {
                    var cookies = document.cookie.split(";");
                    for (var i = 0; i < cookies.length; i++) {
                        if (cookies[i] && !cookies[i].includes("view")) {
                            var equals = cookies[i].indexOf("=");
                            var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                        }
                    }

                    window.location.replace(`${BASE_URL}/logout`);
                }
            })
    }
    const ReloadStockPage = () => {
        setLoader(true)
        axios({
            method: "get",
            url: `${NODE_API_URL}/INTL/fetchDiamondsByCertificatesOrStockNumber`,
            headers: {
              "Content-Type": "application/json",
               "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
          },
            params: location.state.finalData,
          })
          .then(function(res) {
            setStockAPI(res.data.data)
            history.push({
                pathname: "/search-grid/stock-search",
                state: {
                    searchresult:res.data.data,
                    oldvalues:null,
                    finalData:location.state.finalData
                },
                    // state: decompress(res.data.ProductDetails),
                });
                setTimeout(() => {
                    setLoader(false)
                   },1000) 
          })
          .catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    }
    useEffect(() => {
        window.onbeforeunload = (event) => {
            sessionStorage.setItem("pageReloaded", "1")
        };
    }, []);
    useEffect(() => {
        const getpagereload = sessionStorage.getItem("pageReloaded")
        if (getpagereload === "1") {
            if(!location.state.oldvalues){
                ReloadStockPage()
               }else{
                ReloadPage()
               }
            sessionStorage.setItem("pageReloaded", "0")
        }
    }, [])
    useEffect(() => {
            setStockAPI(location.state.searchresult)
            if(!location.state.oldvalues){
                setStoneCount(location.state.searchresult.length)
            }
        },[location.state])
    useEffect(() => {
        const handleResize = () => {
            setIsDropdown(window.innerWidth < 768);
        };
    
        handleResize(); // Check initial size
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if(location.state.oldvalues){
            setCountLoader(true)
            const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
            let len = Object.keys(location.state.oldvalues).length;
            const temp = location.state.oldvalues;
            let data = "";
            for (let key in temp) {
                len--;
                let arr = temp[key];
                if (len) {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                    }
                } else {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                    }
                }
            }
            const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":1, "conversionrate":"${conversionrate}","fancy":"${fancy}","search_result_id":${StockAPI[0].search_result_id}}`;
            // localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
            axios({
                method: "POST",
                url: `${NODE_API_URL}/INTL/countDiamond`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: finalData,
            })
            .then(function(rescount) {
                // setStoneCount(JSON.stringify(rescount.data.data));
                setCountLoader(false)
                setStoneCount(rescount.data.count)
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
        else{
            setStoneCount(location.state.searchresult.length)
            setCountLoader(false)
        }
    },[updatecount])

    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
    };
    const [totalcarat,setTotalCarat] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [diamondcount,setDiamondcount] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selected.map(value => {
            const finddata = StockAPI.find(element => element.stockID.toString() === value.toString())
            if(finddata){
                temptotalcarat += finddata.carat
                temptotalinrcar += (selected.length>0?finddata.ourRate/selected.length:0)
                temptotalourprice += finddata.ourPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * conversionrate):0)
        setTotalOurPrice(temptotalourprice * conversionrate)
        let selecteddata = selected.map(value => {
            const finddata = StockAPI.find(val => val.stockID.toString() === value.toString())
            if(finddata){
                return {
                    "Certi_NO":finddata.certificate,
                    "diamond_type":finddata.diamondType,
                    "tracklisted_price_usd":finddata.ourPrice,
                    "tracklisted_price":finddata.ourPriceExchange,
                    "cur_symbol_name":finddata.cur_symbol_name,
                    "cur_rate":finddata.cur_rate
                }
            }
        })
        setDiamondDetail(selecteddata)
    },[selected])
    useEffect(() => {
        if(StockAPI.length !== parseFloat(stoneCount) && page !== 1){
            HandlePagination(page);
        }
    }, [page]);

    const HandlePagination = (pageno) => {
        setinfiniteLoader(true)
        const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
        window.Nat = location.state.oldvalues;
        let len = Object.keys(location.state.oldvalues).length;
        const temp = location.state.oldvalues;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":${pageno},"sortobj":${JSON.stringify(sortobj)}, "conversionrate":"${conversionrate}","curr":"${symbolname}","fancy":"${fancy}","loginLogId":${Cookies.get("_LogId")} }`;
        if(location.state.oldvalues.diamond_type === "N"){
            localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
        }
        else{
            localStorage.setItem("modifysearchlab",JSON.stringify({...location.state.oldvalues}))
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/searchDiamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        })
        .then(function(res) {
            setStockAPI([
                ...StockAPI,
                ...res.data.data
            ])
            // setPage(pageno)
            setinfiniteLoader(false)
            // history.push({
            //     pathname: "/search-grid",
            //     state: {
            //         searchresult:res.data.data,
            //         oldvalues:location.state.oldvalues,
            //         page:pageno
            //     },
            //         // state: decompress(res.data.ProductDetails),
            //     });
        })
        .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }
   
    const onScroll = () => {
        const div = scrollRef.current;
        if (Math.round(div.scrollTop + div.clientHeight + 1) >= div.scrollHeight && StockAPI.length > 0) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        const div = scrollRef.current;
        if(div && !infiniteloader){
            div.addEventListener('scroll', onScroll);
            return () => div.removeEventListener('scroll', onScroll);
        }
    }, [StockAPI,scrollRef,infiniteloader]);

    const ChangeView = () => {
        SetCookies("view","search-result")
        history.push({
            pathname: `/search-result/${StockAPI[0].diamondType}`,
            // state: StockAPI,
            state: {
                searchresult:location.state.searchresult,
                oldvalues:location.state.oldvalues,
                sortobj:sortobj,
                finalData:location.state.finalData
            }
            // state: decompress(res.data.ProductDetails),
        });
    };

    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    // console.log(wishlistdata,"wishlistdata")
    const [diamondselected,setDiamondSelected] = useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [comparePopup,setComparePopup] = useState(false)
    const CompareDiam = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        if(selectedFlatRows.length === 1){
            alert("Please Select Minimum 2 Diamonds")
            return
        }
        if(selectedFlatRows.length > 4){
            alert("Only 4 diamonds allowed")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.stockID.toString())
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        setDiamondSelected(selectedvalues)
        setComparePopup(true)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
      }
    const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.stockID.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            // }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.stockID.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.carat
                temptotalourprice += finddata.ourPrice
                temptotalinrcar += (diamondcount>0?finddata.ourRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
      const [swipeableDrawer, setSwipeableDrawer] = useState({
        right: false,
      });
  
      const toggleDrawer = (side, open) => event => {
          if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
          }
      
          setSwipeableDrawer({ ...swipeableDrawer, [side]: open });
        };
        const AddToTracklist = () => {
            if(diamonddetails.length === 0){
                setSwalWarnOpen(true)
                setAlertMessage("Please Select at least One Record ...!!")
                return
            }
            setShow(true)
        }
        useEffect(() => {
            if(location.state.sortobj){
                setSortObj(location.state.sortobj)
            }
        },[])
        useEffect(() => {
            if(sortobj && sortobj.id){
                setLoader(true)
                const fancy = location.state.oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
                window.Nat = location.state.oldvalues;
                let len = Object.keys(location.state.oldvalues).length;
                const temp = location.state.oldvalues;
                let data = "";
                for (let key in temp) {
                    len--;
                    let arr = temp[key];
                    if (len) {
                        if (Array.isArray(arr)) {
                            let str = "";
                            str += "'" + arr.join("','") + "'";
                            data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                        } else {
                            data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                        }
                    } else {
                        if (Array.isArray(arr)) {
                            let str = "";
                            str += "'" + arr.join("','") + "'";
                            data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                        } else {
                            data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                        }
                    }
                }
                const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${location.state.oldvalues.diamond_type}" ,"page":1, "conversionrate":"${conversionrate}","curr":"${symbolname}","sortobj":${JSON.stringify(sortobj)},"fancy":"${fancy}","loginLogId":${Cookies.get("_LogId")} }`;
                if(location.state.oldvalues.diamond_type === "N"){
                    localStorage.setItem("modifysearch",JSON.stringify({...location.state.oldvalues}))
                }
                else{
                    localStorage.setItem("modifysearchlab",JSON.stringify({...location.state.oldvalues}))
                }
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/INTL/searchDiamond`,
                    headers: {
                        "Content-Type": "application/json",
                         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                    },
                    data: finalData,
                })
                .then(function(res) {
                    setStockAPI(res.data.data)
                    setPage(1)
                    setLoader(false)
                    history.push({
                        pathname: `/search-grid/${StockAPI[0].diamondType}`,
                        state: {
                            searchresult:res.data.data,
                            oldvalues:location.state.oldvalues,
                            page:1
                        },
                            // state: decompress(res.data.ProductDetails),
                        });
                })
                .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
            }
        },[sortobj])

        function numberWithCommas(x) {
            if (x) {
                return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return "0.00";
            }
        }
        const deselectAll = () => {
            setSelected([])
          };
          const DropdownWithPortal = ({ children, ...props }) => {
            return createPortal(
              <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
              document.body
            );
          };
    return (<>
        <TracklistStone SearchId={StockAPI[0].search_result_id} show={show} setShow={setShow} language={language} diamonddetails={diamonddetails} userinfo={userinfo} symbolname={symbolname} conversionrate={conversionrate}/>
        <SwipeableDrawer
            anchor="right"
            open={swipeableDrawer.right}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            {/* {sideList('right')} */}
            <ModifiedSearchOffCanvasGrid language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage} setPage={setPage} conversionrate={conversionrate} symbolname={symbolname} setUpdateCount={setUpdateCount} updatecount={updatecount} setSortObj={setSortObj} userinfo={userinfo}/>
        </SwipeableDrawer>
        {/* <button type="button" className="btn btn-icon btn-light-primary mr-2 text-nowrap" onClick={(e) => ChangeView()}><FaColumns /></button> */}
        <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language} userinfo={userinfo}/>  
        <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language} userinfo={userinfo}/>  
        <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
        <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} /> 
        <CompareDiamond onHide={() => setComparePopup(false)} show={comparePopup} diamondselected={diamondselected} language={language} symbol={symbol}/>
        {loader?<SearchGridSkeleton/>:StockAPI.length > 0 ?
            <div
                className={`card searchResultCard card-custom card-sticky ${
                    StockAPI.length > 0 && StockAPI[0].diamondType === "L" ? "labTab" : "natTab"
                }`}
            >
                {selected.length>0?
                    <>
                        {isDropdown ? 
                                <div className="card-header selecetedHeader custom-scrollbar text-nowrap flex-nowrap pl-10">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <button type="button" className="btn btn-primary mr-2 px-8 text-nowrap"  onClick={() => BuyDiamond(selected) }> <BsLightningFill className='font-size-h5' />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                        <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => HoldDiamond(selected) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="clear" className="btn-hover-light-dark">
                                                {/* <BsSortDown /> {getSelectedOption()} <RiArrowDropDownLine /> */}
                                                <FaEllipsisH />
                                            </Dropdown.Toggle>
                                            <DropdownWithPortal >
                                                <React.Fragment >
                                                    <Dropdown.Item 
                                                        style={{ padding:" 0.35rem 1.25rem"}}
                                                        onClick={() => AddToTracklist() }
                                                    >
                                                        <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="18px" width="18px" /> {language.TRACKLIST_STONE_BTN}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        style={{ padding:" 0.35rem 1.25rem"}}
                                                        onClick={() =>  CompareDiam(selected) }
                                                    >
                                                        <IoGitCompareOutline className='font-size-h5' />{language.DIAMOND_COMPARISION_BTN}
                                                    </Dropdown.Item>
                                                    
                                                </React.Fragment>
                                            </DropdownWithPortal>
                                        </Dropdown>
                                    </div>
                                    <div className="card-toolbar flex-nowrap">
                                        <Sorting sortobj={sortobj} setSortObj={setSortObj}/>
                                        <div>
                                            <span className="selected-diamond-box mx-2">
                                                <span className='cursor-pointer' ><IoMdClose onClick={deselectAll} className='text-dark'/></span> {selected.length} {language.DIAMOND_COMPARISION_SELDIAMONDS} | {language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {symbol}{numberWithCommas(parseFloat(totalourprice))}
                                            </span>
                                        </div>
                                        <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> List view </Tooltip> } >
                                            <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5"  onClick={(e) => ChangeView()}><CiBoxList /></button>
                                        </OverlayTrigger>
                                        <span>{location.state.oldvalues?<button className="btn btn-clear btn-hover-light-dark mr-1"  onClick={toggleDrawer('right', true)}><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Modify.svg")} height="18px" width="18px" />{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}</span>
                                    </div>
                                </div>
                            :
                                <div className="card-header text-nowrap flex-nowrap custom-scrollbar selecetedHeader pl-10">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <button type="button" className="btn btn-primary px-8 mr-2 text-nowrap"  onClick={() => BuyDiamond(selected) }> <BsLightningFill className='font-size-h5' />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                        <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => HoldDiamond(selected) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                                        <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => AddToTracklist() }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="18px" width="18px" /> {language.TRACKLIST_STONE_BTN}</button>
                                        <button type="button" className="btn btn-hover-light-dark mr-1 font-size-h5"  onClick={() => CompareDiam(selected) }><IoGitCompareOutline />{language.DIAMOND_COMPARISION_BTN}</button>
                                    </div>
                                    <div className="card-toolbar flex-nowrap">
                                        <Sorting sortobj={sortobj} setSortObj={setSortObj}/>
                                        <div>
                                            <span className="selected-diamond-box mx-2">
                                                <span className='cursor-pointer' ><IoMdClose onClick={deselectAll} className='text-dark'/></span> {selected.length} {language.DIAMOND_COMPARISION_SELDIAMONDS} | {language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {symbol}{numberWithCommas(parseFloat(totalourprice))}
                                            </span>
                                        </div>
                                        <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> List view </Tooltip> } >
                                            <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5"  onClick={(e) => ChangeView()}><CiBoxList /></button>
                                        </OverlayTrigger>
                                        <span>{location.state.oldvalues?<button className="btn btn-clear btn-hover-light-dark mr-1"  onClick={toggleDrawer('right', true)}><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Modify.svg")} height="18px" width="18px" />{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}</span>
                                    </div>
                                </div>
                        }
                    </>
                    
                :
                    <div className="card-header p-0">
                        <div className="card-title align-items-center">
                            {countloader?<div className="card-label text-dark">Loading Count...</div>:<div className="card-label text-dark">{stoneCount} {StockAPI.length > 0 && StockAPI[0].diamondType === "L" ? "Lab Grown" : "Natural"} {language.SEARCHLIST_DIAMONDSFOUND} </div>}
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                            <Sorting sortobj={sortobj} setSortObj={setSortObj}/>
                            <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> List view </Tooltip> } >
                                <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5"  onClick={(e) => ChangeView()}><CiBoxList /></button>
                            </OverlayTrigger>
                            <span>{location.state.oldvalues?<button className="btn btn-clear btn-hover-light-dark mr-1"  onClick={toggleDrawer('right', true)}><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Modify.svg")} height="18px" width="18px" />{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}</span>
                        </div>
                    </div>
                }
                <div className="card-body  mt-3  bg-white custom-scrollbar search-grid-card"  ref={scrollRef}>
                    <div className={`d-flex flex-wrap justify-content-${StockAPI.length>3 ? "around" : "start"} gridData`}>
                        {StockAPI.map((val,index) => {
                            const isItemSelected = isSelected(val.stockID);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <SearchGridDesignNew
                                    // shape={val.SHAPE}
                                    key={val.stockID}
                                    {...val}
                                    isItemSelected={isItemSelected}
                                    labelId={labelId}
                                    handleClick={handleClick}
                                    conversionrate={conversionrate}
                                    symbol={symbol}
                                    language={language}
                                    HoldDiamond={HoldDiamond}
                                    BuyDiamond={BuyDiamond}
                                    setStockAPI={setStockAPI}
                                    StockAPI={StockAPI}
                                    page={page}
                                    // location={location}
                                    userinfo={userinfo}
                                    symbolname={val.cur_symbol_name}
                                />
                            );
                        })}
                    </div>
                    {infiniteloader?
                        <div className="card-body pt-5 pb-0">
                            <div className="d-flex flex-wrap justify-content-between gridData">
                                {Array.from(Array(12), (e, i) => {
                                    return (
                                        <div className="mb-5 px-2" style={{width:"280px"}} key={i}>
                                            <div className="row m-0 card rounded-0">
                                                <div className="col-12 p-0 d-flex align-items-center justify-content-center" >
                                                    <Skeleton variant="rect" height={220} width={280} />
                                                </div>
                                                <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                                    <Skeleton variant="text" width={60} />
                                                    <Skeleton variant="text" width={60} />
                                                    <Skeleton variant="text" width={20} />
                                                </div>
                                                <hr className="w-100 my-2" />
                                                <div className="col-12 d-flex px-2 align-items-center">
                                                    <span className="mr-3"><Skeleton variant="circle" width={35} height={35} /></span>
                                                    <Skeleton variant="text" width={200} height={30}/>
                                                </div>
                                                <hr className="w-100 my-2" />
                                                <div className="col-12 d-flex justify-content-between align-items-center">
                                                    <Skeleton variant="rect" width={100}  height={15}/>
                                                    <Skeleton variant="rect" width={100}  height={15}/>
                                                </div>
                                                <hr className="w-100 my-2" />
                                                <div className="col-12 d-flex justify-content-between align-items-center">
                                                    <Skeleton variant="text" width={80} />
                                                    <Skeleton variant="text" width={40} />
                                                    <Skeleton variant="text" width={40} />
                                                    <Skeleton variant="text" width={20} />
                                                </div>
                                                <hr className="w-100 my-2" />
                                                <div className="col-12 mb-2">
                                                    <Skeleton variant="text" width={180} />
                                                    <Skeleton variant="text" width={140} />
                                                </div>
                                                <hr className="w-100 my-2" />
                                                <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                                    <Skeleton variant="rect" width={160} height={40} />
                                                    <Skeleton variant="rect" width={70} height={40}/>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                    </div>:""}
                    {StockAPI.length === parseFloat(stoneCount)?<div className="d-flex align-items-center justify-content-center">No more stones found</div>:""}
                </div>
            </div>:!infiniteloader?
                <div className="card custom-card">
                    <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                        <div className="text-center">
                            <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                            <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>{location.state.modifymsg}</strong></div>
                            <div class="mt-3 text-muted">Please Modify Your Search</div>
                            {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                            <SwipeableDrawer
                                anchor="right"
                                open={swipeableDrawer.right}
                                onClose={toggleDrawer('right', false)}
                                onOpen={toggleDrawer('right', true)}
                            >
                                {/* {sideList('right')} */}
                                <ModifiedSearchOffCanvasGrid language={language} diamond_type={dimondType} setStockAPI={setStockAPI} oldvalues={location.state.oldvalues} setSwipeableDrawer={setSwipeableDrawer} setLoader={setLoader} setModifyMessage={setModifyMessage} setPage={setPage} conversionrate={conversionrate} symbolname={symbolname} setUpdateCount={setUpdateCount} updatecount={updatecount} setSortObj={setSortObj} userinfo={userinfo}/>
                            </SwipeableDrawer>
                            {location.state.oldvalues?<button className="btn btn-primary mt-5 px-8" onClick={toggleDrawer('right', true)}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>:""}
                        </div>
                    </div>
                </div>
            :""}
            
    </>);
}